:root {
  /* ####################################################### */
  /* COLORS */
  /* ####################################################### */

  /*  Primary Colors
        Primary Colors are used in the
        RoomReady logos and should be
        used on all or most items
        representing RoomReady.
  */
  --blue: rgb(116, 157, 211);
  --green: rgb(140, 198, 62);

  /*  Non-Standard 
      These are not part of the official RoomReady 
      palette, but are used on web pages.
  */
  --orange: rgb(224, 128, 36);
  --orange_accent: rgb(134, 76, 22);

  /*  Coordinating Colors
        Coordinating Colors are recommended
        to help maintain a coordinated
        look across the company.
        This set of colors are standard in
        many different programs.
  */
  --grey_coordinating: rgb(110, 111, 113);
  --black_coordinating: black;
  --white_coordinating: white;

  /*  Accent Colors
        Accent Colors should be used
        as an accent or to reverse out
        the RoomReady Logo when the
        solution requires. They are dark
        enough to allow the Primary Colors
        to stand out without resorting to a
        field of black, which can feel
        oppressive.
  */
  --white_accent: rgb(241, 242, 242);
  --blue_accent: rgb(74, 89, 110);
  --green_accent: rgb(63, 90, 28);
}

.App {
  /* text-align: center; */
  background-color: var(--blue_accent);
  /* height: 100vh; */
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100vw; 
  overflow: hidden; /* Hide scrollbars */
}

.App-logo {
  height: 3vmin;
  margin-top: 0;
  margin-bottom: 16px;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.float-right{
  float: right;
  margin-right: 16px;
}