input[type=button].ButtonGreen {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    background-color: var(--green);
    color: white;
    width: 172px;
    height: 32px;
    text-align: center;
    margin-top: 32px;
    border: 0 solid white;
    border-radius: 8px;
}