input[type="button"].ButtonRound {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    
    padding-top: 3px;
    font-size: 24px;
    
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 0 solid white;

}