div.Heading {
    display: block;
    color: white;
    padding: 8px;
    width: 100vw - 16px;
    height: 60px;
    border: 2px solid white;
    border-top: 0 solid transparent;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
}