div.ContentProductGroup {
    display: inline;
    position: absolute;
    width: 48%;
    top: 0;
    bottom: 0;
    margin: 8px;
    padding: 24px;
}

span {
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
  
  