body {
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* ####################################################### */
  /* COLORS */
  /* ####################################################### */

  /*  Primary Colors
        Primary Colors are used in the
        RoomReady logos and should be
        used on all or most items
        representing RoomReady.
  */
  --blue: rgb(116, 157, 211);
  --green: rgb(140, 198, 62);

  /*  Non-Standard 
      These are not part of the official RoomReady 
      palette, but are used on web pages.
  */
  --orange: rgb(224, 128, 36);
  --orange_accent: rgb(134, 76, 22);

  /*  Coordinating Colors
        Coordinating Colors are recommended
        to help maintain a coordinated
        look across the company.
        This set of colors are standard in
        many different programs.
  */
  --grey_coordinating: rgb(110, 111, 113);
  --black_coordinating: black;
  --white_coordinating: white;

  /*  Accent Colors
        Accent Colors should be used
        as an accent or to reverse out
        the RoomReady Logo when the
        solution requires. They are dark
        enough to allow the Primary Colors
        to stand out without resorting to a
        field of black, which can feel
        oppressive.
  */
  --white_accent: rgb(241, 242, 242);
  --blue_accent: rgb(74, 89, 110);
  --green_accent: rgb(63, 90, 28);
}

.App {
  /* text-align: center; */
  background-color: rgb(74, 89, 110);
  background-color: var(--blue_accent);
  /* height: 100vh; */
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100vw; 
  overflow: hidden; /* Hide scrollbars */
}

.App-logo {
  height: 3vmin;
  margin-top: 0;
  margin-bottom: 16px;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.float-right{
  float: right;
  margin-right: 16px;
}
div.Heading {
    display: block;
    color: white;
    padding: 8px;
    width: 100vw - 16px;
    height: 60px;
    border: 2px solid white;
    border-top: 0 solid transparent;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
}
div.HeadingLogo {
    display: inline;
}
div.HeadingCaption {
    display: inline;
    margin: 8px 8px 8px 8px;
    height: 100%;
    width: 100%;
    font-size: 36px;
    font-weight: bold;
}
div.HeadingLogout {
    display: inline-block;
    height: 26px;
    margin: 0 0 0 auto;
}
button#btnSignIn, button#btnSignOut {
    display: inline-block;
    /* height: 26px; */
    margin-left: auto;
    margin-bottom: 14px;
}
div.Content {
    position: absolute;
    top: 101px;
    bottom: 2px;
    width: 100vw;
    min-width: 1050px;
    display: block;
    color: white;
    padding: 16px;
}
div.ContentProductGroup {
    display: inline;
    position: absolute;
    width: 48%;
    top: 0;
    bottom: 0;
    margin: 8px;
    padding: 24px;
}

span {
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}
  
  
div.ContentNewProductGroup {
    left: 0;
    padding-right: 12px;
}
div.ColumnCaption {
    font-size: 24px;
    text-decoration: underline;
}
div.ContentProductGroupLabel {
    width: 95%;
    font-size: 14px;
    margin-top: 24px;
    text-decoration: none;
    color: white;
}
input[type=button].ButtonGreen {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    background-color: var(--green);
    color: white;
    width: 172px;
    height: 32px;
    text-align: center;
    margin-top: 32px;
    border: 0 solid white;
    border-radius: 8px;
}
input[type="button"].ButtonRound {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    
    padding-top: 3px;
    font-size: 24px;
    
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 0 solid white;

}
input[type=text] {
    height: 40px;
    width: 95%;
    border: 0 solid white;
    border-radius: 8px;
    margin-top: 16px;
    padding: 0 16px 0 16px;
}
div.InventoryItems {
    margin-top: 8px;
    width: 95%;
    min-height: 32px;
    max-height: 120px;
    border: 1px solid grey;
    border-radius: 8px;
    overflow: hidden;
    overflow-y: scroll;
}
div.InventoryItem {
    width: 100%;
    height: 24px;
}

input[type="checkbox"].InventoryItem {
    margin-top: 8px;
    margin-right: 10px;

}
/* label.ProductGroupItem {
} */
div.ContentEditProductGroup {
    border-left: 1px solid white;
    right: 32px;
    margin-left: 18px;
    overflow-x: hidden;
    overflow-y: auto;
}
div.EmphaticLabel {
    width: 95%;
    font-size: 18px;
    font-weight: 900;
    margin-top: 24px;
    text-decoration: none;
    color: white;
}
div.ProductGroupList {
    margin-top: 8px;
    width: 95%;
    min-height: 32px;
    max-height: 120px;
    border: 1px solid grey;
    border-radius: 8px;
    overflow: hidden;
    overflow-y: scroll;
}
div.ProductGroupListItem {
    color: white;
    width: 100%;
}
span.ProductGroupListItemSpan {
    display: inline-block;
    width: 100%;
}
div.ProductGroupInventoryItems {
    margin-top: 8px;
    width: 95%;
    min-height: 32px;
    max-height: 220px;
    border: 1px solid grey;
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: scroll;
}
div.ProductGroupItem {
    width: 100%;
    height: 24px;
}

div.editInventoryItem {
    display: inline-block;
    white-space: nowrap;
    width: 95%;
}

input[type="checkbox"].ProductGroupItem {
    margin-top: 8px;
    margin-right: 10px;

}
/* label.ProductGroupItem {
} */
