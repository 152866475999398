div.InventoryItem {
    width: 100%;
    height: 24px;
}

input[type="checkbox"].InventoryItem {
    margin-top: 8px;
    margin-right: 10px;

}
/* label.ProductGroupItem {
} */