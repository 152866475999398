div.ProductGroupItem {
    width: 100%;
    height: 24px;
}

div.editInventoryItem {
    display: inline-block;
    white-space: nowrap;
    width: 95%;
}

input[type="checkbox"].ProductGroupItem {
    margin-top: 8px;
    margin-right: 10px;

}
/* label.ProductGroupItem {
} */